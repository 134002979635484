
import { defineComponent, ref, computed } from "vue";
import { Respondent } from "../../core/types/RespondentTypes";

export default defineComponent({
  name: "incline-autocomplete",
  props: {
    dataList: {
      type: Array as () => Array<Respondent>,
      required: true,
    },
    userRole: {
      type: [String],
      default: "",
    },
  },
  components: {},
  emits: ["respondent-selected"],
  setup(props, { emit }) {
    let searchTerm = ref("");
    const clientList = computed(() => {
      return props.dataList;
    });
    const searchClients = computed(() => {
      if (searchTerm.value === "") {
        return [];
      }

      let matches = 0;

      return clientList.value.filter((client) => {
        if (
          client.lastName
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return client;
        }
      });
    });

    const selectedRespondent = ref<Respondent>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      hireStatus: "",
      jobTitle: "",
      clients: [],
    });
    const selectRecipient = (client) => {
      selectedRespondent.value = client;
      searchTerm.value = "";
      emit(
        "respondent-selected",
        selectedRespondent.value.firstName,
        selectedRespondent.value.lastName,
        selectedRespondent.value.email,
        selectedRespondent.value.aspNetUserId,
        selectedRespondent.value.hireStatus,
        selectedRespondent.value.jobTitle,
        selectedRespondent.value.clients
      );
    };

    return {
      selectedRespondent,
      selectRecipient,
      searchTerm,
      searchClients,
      clientList,
    };

    /* It be enterd in the Parent Component to accept the emit

    <!--begin:: AutoComplete -->
    <Autocomplete
        :dataList="clientList"
        @current-change="onSelected"
    ></Autocomplete>
    <!--end:: AutoComplete -->

// Example of interface MAKE SURE object values are EXACT in object
    interface Client {
        clientId: number;
        name: string;
        numOfEmployees: number;
        primaryContactName: string;
        primaryContactEmail: string;
        active: boolean;
        deleted: boolean;
        locations: ClientLocation[];
    }

  setup() {

    const client = ref<Client>({
      clientId: 0,
      name: "",
      numOfEmployees: 0,
      primaryContactName: "",
      primaryContactEmail: "",
      active: true,
      deleted: false,
      locations: [] as ClientLocation[],
    });


    function onSelected(event) {
    console.log("val: " + event.numOfEmployees);
    client.value = event;
    }
   }
*/
  },
});
