
import {
  defineComponent,
  ref,
  computed,
  reactive,
  toRaw,
  toRefs,
  onMounted,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { EmailLookup } from "../../../core/types/LookupTypes";
import {
  NewRespondentRequestDto,
  Respondent,
  RespondentListItemRequestDto,
} from "../../../core/types/RespondentTypes";

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedFolder: number;
}

export default defineComponent({
  name: "new-email-respondent-modal",
  props: [
    "displayModal",
    "userRole",
    "licensedDistributorId",
    "licensedDistAssocId",
    "clientId",
  ],
  components: { Field, Form, ErrorMessage },
  emits: ["check-JobTitle", "new-respondent-entered"],
  setup(props, { emit }) {
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedFolder: 0,
    };
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });

    const emailLookupResponse = ref<EmailLookup>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      userExists: false,
    });

    const respondentListItemRequestDto = ref<RespondentListItemRequestDto>({
      clientId: props.clientId,
      email: "",
    });

    onMounted(() => {
      if (props.userRole === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        props.userRole === "LicensedDistributor" ||
        props.userRole === "LDAssociate"
      ) {
        getClients();
      } else if (
        props.userRole === "PowerUser" ||
        props.userRole === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const newEmailRespondent = computed(() => {
      return store.getters.getNewEmailRespondent;
    });

    const getClients = () => {
      if (props.userRole === "LicensedDistributor") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistributorId;
      } else if (props.userRole === "LDAssociate") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistAssocId;
      }

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        dropDownSetup.selectedClient = props.clientId;
      }

      store
        .dispatch(
          Actions.GET_CLIENT_BEHAVIORAL_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving client folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const newRespondent = ref<NewRespondentRequestDto>({
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      clientId: 0,
      folderId: 0,
      respondentAspNetUserId: "",
      userExists: false,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      jobTitle: Yup.string().required().label("Job Title"),
    });

    function lookupEmail() {
      store
        .dispatch(Actions.LOOKUP_EMAIL, emailLookupResponse.value)
        .then(() => {
          emailLookupResponse.value = store.getters.getEmailLookupResponse;
          newRespondent.value.email = emailLookupResponse.value.email;

          onEmailLookup();
        });
    }

    function onEmailLookup() {
      if (emailLookupResponse.value.userExists) {
        Swal.fire({
          title: "Duplicate names may exist.",
          html:
            "<b>" +
            emailLookupResponse.value.firstName +
            " " +
            emailLookupResponse.value.lastName +
            "</b><br />" +
            emailLookupResponse.value.email +
            "<br /><br />If this information is correct, click Yes.?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            newRespondent.value.firstName = emailLookupResponse.value.firstName;
            newRespondent.value.lastName = emailLookupResponse.value.lastName;
            newRespondent.value.jobTitle = emailLookupResponse.value.jobTitle;
            newRespondent.value.respondentAspNetUserId =
              emailLookupResponse.value.aspNetUserId;
            newRespondent.value.userExists = true;
          }
        });
      }
    }

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        newRespondent.value.clientId = props.clientId;
      } else {
        newRespondent.value.clientId = dropDownSetup.selectedClient;
      }
      newRespondent.value.folderId = dropDownSetup.selectedFolder;
      // Send login request
      store
        .dispatch(Actions.CREATE_NEW_RESPONDENT, newRespondent.value)
        .then(() => {
          Swal.fire({
            html: "Success. The new recipient has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            respondentListItemRequestDto.value.email =
              newRespondent.value.email;
              respondentListItemRequestDto.value.clientId =
              newRespondent.value.clientId;
            store
              .dispatch(          
                Actions.GET_RESPONDENT_LIST_ITEM,
                respondentListItemRequestDto.value
              )
              .then(() => {
                resetForm();
                modalRef.value?.click();
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });

            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newRespondent.value = {
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        clientId: 0,
        folderId: 0,
        respondentAspNetUserId: "",
        userExists: false,
      };
      emailLookupResponse.value = {
        aspNetUserId: "",
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        userExists: false,
      };
      dropDownSetup.selectedLicensedDistributor = 0;
      dropDownSetup.selectedClient = 0;
      dropDownSetup.selectedFolder = 0;
    }
    const emailRecipient = ref<Respondent>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      hireStatus: "",
      jobTitle: "",
      clients: [],
    });
    const newEmailRecipient = (client) => {
      emailRecipient.value = client;
      emit(
        "new-respondent-entered",
        emailRecipient.value.firstName,
        emailRecipient.value.lastName,
        emailRecipient.value.email,
        emailRecipient.value.aspNetUserId,
        emailRecipient.value.hireStatus,
        emailRecipient.value.jobTitle,
        emailRecipient.value.clients
      );
    };

    watch([newEmailRespondent], () => {
      emit(
        "new-respondent-entered",
        newEmailRespondent.value.firstName,
        newEmailRespondent.value.lastName,
        newEmailRespondent.value.email,
        newEmailRespondent.value.aspNetUserId,
        newEmailRespondent.value.hireStatus,
        newEmailRespondent.value.jobTitle,
        newEmailRespondent.value.clients
      );
    });

    return {
      newRespondent,
      emailRecipient,
      newEmailRespondent,
      respondentListItemRequestDto,
      newEmailRecipient,
      closeModal,
      resetForm,
      modalRef,
      licensedDistributors,
      clientFolderList,
      clientList,
      ...toRefs(dropDownSetup),
      getClients,
      getClientFolders,
      lookupEmail,
      onEmailLookup,
      emailLookupResponse,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
