<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_new_respondent"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitRegister"
            id="new_respondont_form"
            :validation-schema="registration"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Create New Recipient</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Email</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="email"
                placeholder=""
                name="email"
                autocomplete="off"
                v-model="emailLookupResponse.email"
                @change="lookupEmail()"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="email" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">First Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder=""
                  name="firstName"
                  autocomplete="off"
                  v-model="newRespondent.firstName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="firstName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Last Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder=""
                  name="lastName"
                  autocomplete="off"
                  v-model="newRespondent.lastName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Recipient Job Title</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder=""
                name="jobTitle"
                autocomplete="off"
                v-model="newRespondent.jobTitle"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="jobTitle" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <!--begin::Input group-->
                <div class="fv-row">
                  <!--begin:: Licensed Distributor Dropdown-->
                  <div
                    v-if="userRole === 'SuperAdmin'"
                    class="col-md-12 fv-row mb-7"
                  >
                    <label for="licensedDistributor" class="fs-6 fw-bold mb-2"
                      >Licensed Distributor</label
                    >

                    <select
                      name="licensedDistributor"
                      class="form-select form-select-solid"
                      as="select"
                      v-model="selectedLicensedDistributor"
                      @change="getClients()"
                    >
                      <option value="0">Select a Distributor...</option>
                      <option
                        v-for="item in licensedDistributors"
                        :key="item.licensedDistributorId"
                        :label="item.name"
                        :value="item.licensedDistributorId"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <!--end:: Licensed Distributo Dropdown-->
                  <!--begin:: Client Dropdown-->
                  <div
                    class="col-md-12 fv-row mb-7"
                    v-if="
                      userRole === 'SuperAdmin' ||
                      userRole === 'LicensedDistributor' ||
                      userRole === 'LDAssociate'
                    "
                  >
                    <label class="fs-6 fw-bold mb-2">Client</label>

                    <Field
                      name="client"
                      class="form-select form-select-solid"
                      as="select"
                      v-model="selectedClient"
                      @change="getClientFolders()"
                    >
                      <option value="0">Select a Client...</option>
                      <option
                        v-for="item in clientList"
                        :key="item.clientId"
                        :label="item.name"
                        :value="item.clientId"
                      >
                        {{ item.name }}
                      </option>
                    </Field>
                  </div>
                  <!--end:: Client Dropdown-->
                  <!--begin:: Client Folder Dropdown-->
                  <div class="col-md-12 fv-row mb-7">
                    <label class="fs-6 fw-bold mb-2">Locations</label>

                    <Field
                      name="folder"
                      class="form-select form-select-solid"
                      as="select"
                      v-model="selectedFolder"
                    >
                      <option value="0">Select a Location...</option>
                      <option
                        v-for="item in clientFolderList"
                        :key="item.folderId"
                        :label="item.folderName"
                        :value="item.folderId"
                      >
                        {{ item.folderName }}
                      </option>
                    </Field>
                  </div>
                  <!--end:: Client Dropdown-->
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div
                class="col-md-6 fv-row"
                v-if="
                  userRole === 'SuperAdmin' ||
                  userRole === 'LicensedDistributor' ||
                  userRole === 'LDAssociate'
                "
              >
                <p class="fs-6 fw-bold mb-2">
                  <span class="text-danger fs-6 fw-bold mb-2">Note:</span> If
                  you don't select a client, the emailed survey will be placed
                  in your Personal Folder
                </p>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <div class="row g-9 mb-8">
              <!--begin:: Select Client-->
            </div>

            <!--end:: Select Client-->

            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="kt_modal_new_email_respondent_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <button
                id="kt_respondent_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  reactive,
  toRaw,
  toRefs,
  onMounted,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { EmailLookup } from "../../../core/types/LookupTypes";
import {
  NewRespondentRequestDto,
  Respondent,
  RespondentListItemRequestDto,
} from "../../../core/types/RespondentTypes";

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedFolder: number;
}

export default defineComponent({
  name: "new-email-respondent-modal",
  props: [
    "displayModal",
    "userRole",
    "licensedDistributorId",
    "licensedDistAssocId",
    "clientId",
  ],
  components: { Field, Form, ErrorMessage },
  emits: ["check-JobTitle", "new-respondent-entered"],
  setup(props, { emit }) {
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedFolder: 0,
    };
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });

    const emailLookupResponse = ref<EmailLookup>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      userExists: false,
    });

    const respondentListItemRequestDto = ref<RespondentListItemRequestDto>({
      clientId: props.clientId,
      email: "",
    });

    onMounted(() => {
      if (props.userRole === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        props.userRole === "LicensedDistributor" ||
        props.userRole === "LDAssociate"
      ) {
        getClients();
      } else if (
        props.userRole === "PowerUser" ||
        props.userRole === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const newEmailRespondent = computed(() => {
      return store.getters.getNewEmailRespondent;
    });

    const getClients = () => {
      if (props.userRole === "LicensedDistributor") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistributorId;
      } else if (props.userRole === "LDAssociate") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistAssocId;
      }

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        dropDownSetup.selectedClient = props.clientId;
      }

      store
        .dispatch(
          Actions.GET_CLIENT_BEHAVIORAL_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving client folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const newRespondent = ref<NewRespondentRequestDto>({
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      clientId: 0,
      folderId: 0,
      respondentAspNetUserId: "",
      userExists: false,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      jobTitle: Yup.string().required().label("Job Title"),
    });

    function lookupEmail() {
      store
        .dispatch(Actions.LOOKUP_EMAIL, emailLookupResponse.value)
        .then(() => {
          emailLookupResponse.value = store.getters.getEmailLookupResponse;
          newRespondent.value.email = emailLookupResponse.value.email;

          onEmailLookup();
        });
    }

    function onEmailLookup() {
      if (emailLookupResponse.value.userExists) {
        Swal.fire({
          title: "Duplicate names may exist.",
          html:
            "<b>" +
            emailLookupResponse.value.firstName +
            " " +
            emailLookupResponse.value.lastName +
            "</b><br />" +
            emailLookupResponse.value.email +
            "<br /><br />If this information is correct, click Yes.?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            newRespondent.value.firstName = emailLookupResponse.value.firstName;
            newRespondent.value.lastName = emailLookupResponse.value.lastName;
            newRespondent.value.jobTitle = emailLookupResponse.value.jobTitle;
            newRespondent.value.respondentAspNetUserId =
              emailLookupResponse.value.aspNetUserId;
            newRespondent.value.userExists = true;
          }
        });
      }
    }

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        newRespondent.value.clientId = props.clientId;
      } else {
        newRespondent.value.clientId = dropDownSetup.selectedClient;
      }
      newRespondent.value.folderId = dropDownSetup.selectedFolder;
      // Send login request
      store
        .dispatch(Actions.CREATE_NEW_RESPONDENT, newRespondent.value)
        .then(() => {
          Swal.fire({
            html: "Success. The new recipient has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            respondentListItemRequestDto.value.email =
              newRespondent.value.email;
              respondentListItemRequestDto.value.clientId =
              newRespondent.value.clientId;
            store
              .dispatch(          
                Actions.GET_RESPONDENT_LIST_ITEM,
                respondentListItemRequestDto.value
              )
              .then(() => {
                resetForm();
                modalRef.value?.click();
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });

            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newRespondent.value = {
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        clientId: 0,
        folderId: 0,
        respondentAspNetUserId: "",
        userExists: false,
      };
      emailLookupResponse.value = {
        aspNetUserId: "",
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        userExists: false,
      };
      dropDownSetup.selectedLicensedDistributor = 0;
      dropDownSetup.selectedClient = 0;
      dropDownSetup.selectedFolder = 0;
    }
    const emailRecipient = ref<Respondent>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      hireStatus: "",
      jobTitle: "",
      clients: [],
    });
    const newEmailRecipient = (client) => {
      emailRecipient.value = client;
      emit(
        "new-respondent-entered",
        emailRecipient.value.firstName,
        emailRecipient.value.lastName,
        emailRecipient.value.email,
        emailRecipient.value.aspNetUserId,
        emailRecipient.value.hireStatus,
        emailRecipient.value.jobTitle,
        emailRecipient.value.clients
      );
    };

    watch([newEmailRespondent], () => {
      emit(
        "new-respondent-entered",
        newEmailRespondent.value.firstName,
        newEmailRespondent.value.lastName,
        newEmailRespondent.value.email,
        newEmailRespondent.value.aspNetUserId,
        newEmailRespondent.value.hireStatus,
        newEmailRespondent.value.jobTitle,
        newEmailRespondent.value.clients
      );
    });

    return {
      newRespondent,
      emailRecipient,
      newEmailRespondent,
      respondentListItemRequestDto,
      newEmailRecipient,
      closeModal,
      resetForm,
      modalRef,
      licensedDistributors,
      clientFolderList,
      clientList,
      ...toRefs(dropDownSetup),
      getClients,
      getClientFolders,
      lookupEmail,
      onEmailLookup,
      emailLookupResponse,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
</script>
